@tailwind base;
@tailwind components;
@tailwind utilities;

body::-webkit-scrollbar {
  width: 14px;
}

body::-webkit-scrollbar-track {
  background: #f1f1f1;
}

body::-webkit-scrollbar-thumb {
  background-color: #180D49;
  border-radius: 6px;
  border: 3px solid #f1f1f1;
}

body::-webkit-scrollbar-thumb:hover {
  background: #020298;
}

.custom-scrollbar::-webkit-scrollbar {
  width: 14px;
}

.custom-scrollbar::-webkit-scrollbar-track {
  background: #f1f1f1;
}

.custom-scrollbar::-webkit-scrollbar-thumb {
  background-color: #180D49;
  border-radius: 6px;
  border: 3px solid #f1f1f1;
}

.custom-scrollbar::-webkit-scrollbar-thumb:hover {
  background: #020298;
}

::-moz-selection {
  /* Code for Firefox */
  color: rgba(255, 30, 30, 0.881);
  background: yellow;
}

::selection {
  color: rgba(255, 30, 30, 0.881);
  background: yellow;
}