.content {
    clip-path: polygon(0 0, 100% 8%, 100% 100%, 0 92%);
  }
.content2 {
    clip-path: polygon(0 8%, 100% 0, 100% 92%, 0 100%);
  }

  .box-container {
    position: relative;
    width: 250px;
    height: 250px;
  }
  
  .black-box {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: black;
    
  }
  
  .white-box {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: white;
    transform: translateX(20px) translateY(20px);
  }

  #instagram > svg:hover{
    fill: url(#gradient-fill);
    transform: scale(1.10);
  }
  #youtube > svg:hover{
    fill: red;
    transform: scale(1.10);
  }
  #linkedin > svg:hover{
    fill: #0077B5;
    transform: scale(1.10);
  }
  #facebook > svg:hover{
    fill: #1877F2;
    transform: scale(1.10);
  }
  #x > svg:hover{
    fill: black;
    transform: scale(1.10);
  }