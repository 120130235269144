.incen-number{
    background: linear-gradient(to right, #651FFF,#00BCD4); 
    background-clip: text;
    -webkit-text-fill-color: transparent; 
    -webkit-background-clip: text;
}

.incen-cont {
    transition: transform 0.5s ease-in-out;
    cursor: default;
}

.incen-cont:hover {
    transform: scale(1.2);
}
/* 
@keyframes drawPath {
  0% {
    stroke-dashoffset: 2520;
  }
  100% {
    stroke-dashoffset: 0;
  }
}
.animated-path {
  stroke-dasharray: 2520;
  stroke-dashoffset: 2520;
  animation: drawPath 5s linear;
} */


/* .glow-light {
    position: absolute;
    top: calc(50% + 2px);
    left: 0;
    width: 80px;
    height: 2px;
    border-radius: 10%;
    background-color: white;
    box-shadow: 0 0 10px 5px rgba(255, 255, 255, 0.8); 
    
    animation: moveLight 4s linear;
  }
  
  @keyframes moveLight {
    0% {
      left: 0;
    }
    100% {
      left: 94%;
    }
  } */


  @keyframes glowAnimation {
    0% {
      stroke-dashoffset: 100%;
      opacity: 0;
    }
    100% {
      stroke-dashoffset: 0;
      opacity: 1;
    }
  }
  .glow-line {
    stroke-dasharray: 100%;
    stroke-dashoffset: 50%;
    opacity: 0;
  }
  .glow-line.active {
    animation: glowAnimation 3.5s linear forwards;
  }

  .img-container {
    position: relative;
    width: 100%;
    height: 100%;
    border-radius: 10%;
    transition: 0.6s;
    z-index: 99;
  }

  .img-container img {
    width: 100%;
    height: 100%;
    border-radius: 10%;
    object-fit: cover;
    transition: 0.6s;
    aspect-ratio: 1/1;
    object-fit: contain;
    mix-blend-mode: color-burn;
  }
  .profile-card {
    position: relative;
    background-color: white;
    border-radius: 10%;
    box-shadow: 0 0 22px #3336;
    transition: 0.6s;
    margin: 0px;
  }
  .profile-card:hover {
    transform: scale(1.05);
    cursor: pointer;
  }
  .profile-card:hover::before,
.profile-card:hover::after {
  transform: scale(1.05);
  filter:blur(2px)
}
  .profile-card::before{
    content: '';
    position: absolute;
    top: -2px;
    left: -2px;
    right: -2px;
    bottom: -2px;
    background: #fff;
    border-radius: 10%;
    z-index: -1;
    transition: 0.6s;
  }
  .profile-card::after{
    content: '';
    position: absolute;
    top: -2px;
    left: -2px;
    right: -2px;
    bottom: -2px;
    border-radius: 10%;
    background: #fff;
    z-index: -2;
    filter: blur(5px);
    transition: 0.6s;
  }